<template>
  <div class="">
    <b-overlay
        variant="white"
        :show="loading"
        spinner-variant="primary"
        blur="1"
        opacity=".75"
        rounded="sm"
    >
      <div class="card">
        <div class="card-header">
          <h3 class="text-primary">{{ title }}</h3>
        </div>
        <div class="card-body">
          <validation-observer ref="FormValidation">
            <b-form class="mb-3">
              <b-row>
                <b-col cols="12">
                  <validation-provider #default="{ errors }" name="Addition Name" rules="required">
                    <b-form-group label="Addition Name" label-for="name">
                      <b-form-input
                          autofocus
                          id="name"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Addition Name"
                          v-model="formData.name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12">
                  <validation-provider #default="{ errors }" name="Price" rules="required">
                    <b-form-group label="Price" label-for="cost">
                      <b-input-group
                          :class="errors[0] ? 'border-danger-for-merge-input' : ''"
                          append="AED"
                          class="input-group-merge">
                        <cleave
                            id="cost"
                            v-model="formData.cost"
                            class="form-control"
                            :state="errors.length > 0 ? false : null"
                            :raw="false"
                            :options="priceFormat"
                            placeholder="Price"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button variant="primary"
                            type="submit"
                            class="float-right"
                            @click.prevent="submit()"
                  >
                    {{ action }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import AdditionService from "@/services/AdditionService";
import Cleave from "vue-cleave-component";

export default {
  name: "AdditionForm",
  components: {
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  props: ['title', 'action'],
  data() {
    return {
      loading: false,
      AdditionService: new AdditionService(),
      formData: {
        id: undefined,
        name: '',
        cost: ''
      },
      priceFormat: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
    }
  },
  methods: {
    submit() {
      this.$refs.FormValidation.validate().then(success => {
        if (success) {
          this.formSubmitted()
        }
      })
    },
    formSubmitted() {
      if (!this.formData.id) {
        this.createAddition()
      } else {
        this.updateAddition()
      }
    },
    createAddition() {
      this.loading = true
      this.AdditionService.create({...this.formData, cost: this.formData.cost.replaceAll(',', '')}).then(res => {
        this.loading = false
        this.resetData()
        this.$refs.FormValidation.reset();
        this.$emit('submitted')
      }, err => {
        console.log(err)
        this.loading = false
      })
    },
    updateAddition() {
      this.loading = true
      this.AdditionService.updateAddition(this.formData.id, this.formData).then(res => {
        this.loading = false
        this.resetData()
        this.$refs.FormValidation.reset();
        this.$emit('submitted')
      }, err => {
        console.log(err)
        this.loading = false
      })
    },
    resetData() {
      this.formData.id = undefined
      this.formData.name = ''
      this.formData.cost = ''
    }
  }
}
</script>

<style scoped>

</style>
