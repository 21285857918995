<template>
  <div class="">
    <!--    <div class="custom-search d-flex justify-content-end">-->
    <!--      <b-form-group>-->
    <!--        <div class="d-flex align-items-center">-->
    <!--          <label class="mr-1">Search</label>-->
    <!--          <b-form-input v-model="params.search" placeholder="Search" type="text" class="d-inline-block"-->
    <!--                        debounce="1000"/>-->
    <!--        </div>-->
    <!--      </b-form-group>-->
    <!--    </div>-->
    <vue-good-table mode="remote"
                    class="mt-1"
                    :pagination-options="{enabled: true}"
                    :totalRows="totalRecords"
                    :rows="rows" :columns="columns"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-per-page-change="onPerPageChange"
                    :isLoading.sync="isLoading">
      <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field === 'status'">
        <span :class="{'skeleton-box': props.row.savingStatus}">
            <b-form-checkbox
                @change="() => changeStatus(props.row.id)"
                :checked="props.row.status === 'Active'"
                switch
                inline
                :disabled="props.row.savingStatus"
            />

        </span>
      </span>
        <span v-else>
            {{ props.formattedRow[props.column.field] }}
      </span>
        <span v-if="props.column.field === 'action'">
             <b-button variant="secondary" :to="{ name: 'edit-addition', params: {addition_id: props.row.id}}">
              <feather-icon icon="SettingsIcon"/>
             </b-button>
      </span>
      </template>
      <template #emptystate>
        <p class="text-center">
          {{ isSorting ? "Loading..." : "There is no data to show!" }}
        </p>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import AdditionService from "@/services/AdditionService";
import {VueGoodTable} from "vue-good-table";

export default {
  name: "AdditionTable",
  components: {VueGoodTable},
  data() {
    return {
      params: {
        page: undefined,
        per_page: undefined,
        sort_keys: undefined,
        sort_dir: undefined,
        search: undefined,
      },
      columns: [
        {label: 'Addition ID', field: 'id'},
        {label: 'Name', field: 'name'},
        {label: 'Price', field: 'cost'},
        {label: 'Created At', field: 'created_at'},
        {label: 'Active', field: 'status', sortable: false},
        // {label: 'Edit', field: 'action', width: '100px', sortable: false},
      ],
      rows: [],
      isLoading: undefined,
      isSorting: true,
      totalRecords: undefined,
      AdditionService: new AdditionService(),
      modalOptions: undefined
    }
  },
  watch: {
    params: {
      handler: function () {
        this.rows = []
        this.getAdditions();
      },
      deep: true
    }
  },
  created() {
    this.getAdditions();
  },
  methods: {
    changeStatus(id) {
      this.rows.find(el => el.id === id).savingStatus = true
      const value = !(this.rows.find(el => el.id === id).status === "Active");
      this.AdditionService.changeStatus(id, value).then(res => {
        this.rows.find(el => el.id === id).status = value ? 'Active' : 'Inactive';
      }).catch(err => {
        const temp = this.rows
        this.rows = []
        this.$nextTick(() => {
          this.rows = temp
        })
      })
          .finally(() => {
            this.rows.find(el => el.id === id).savingStatus = false
          });
    },
    onPerPageChange(page) {
      this.params.page = page.currentPage;
      this.params.per_page = page.currentPerPage;
    },
    onPageChange(active) {
      this.params.page = active.currentPage;
      this.params.per_page = active.currentPerPage;
    },
    onSortChange(params) {
      this.params.sort_keys = [params[0].field];
      this.params.sort_dir = [params[0].type];
    },
    getAdditions() {
      this.isSorting = true;
      this.isLoading = true;
      this.AdditionService.index({...this.params, ...this.propsParams}).then(res => {
        res.data.forEach(row => {
          row.savingStatus = false
          row.cost = (+row.cost).toLocaleString()
        })
        this.rows = res.data;
        this.totalRecords = res.total;
      }).catch(err => {
      })
          .finally(() => {
            this.isSorting = false;
            this.isLoading = false;
          });
    },
  },
}
</script>

<style scoped>

</style>
