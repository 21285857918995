<template>
  <b-card title="Additions Table">
    <div class="mb-sm-0">
      <div class="d-flex align-items-center">
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="float-right"
            variant="primary"
            @click="showForm = !showForm"
        >
          Add Addition
        </b-button>
      </div>
      <AdditionTable ref="AdditionTable"/>
    </div>
    <b-modal
        v-model="showForm"
        centered
        hide-footer
    >
      <AdditionForm @submitted="formSubmitted" :title="'Create Addition'" action="Save"/>
    </b-modal>
  </b-card>
</template>

<script>


import AdditionTable from "@/views/components/additions/AdditionTable";
import Ripple from "vue-ripple-directive";
import '@core/scss/vue/libs/vue-good-table.scss';
import AdditionForm from "@/views/components/additions/AdditionForm";

export default {
  name: "Index",
  components: {AdditionForm, AdditionTable},
  directives: {
    Ripple,
  },
  data() {
    return {
      showForm: false,
    }
  },
  methods: {
    formSubmitted() {
      this.showForm = !this.showForm
      this.$refs.AdditionTable.getAdditions()
    }
  }
}
</script>

<style scoped>

</style>
