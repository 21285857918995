import CrudService from "@/services/shared/http/CrudService";

export default class AdditionService extends CrudService {
    constructor(params = {}) {
        super("additional-options", params);
    }

    changeStatus(id, value) {
        return this.api.PATCH(`toggle-additional-option/${id}`, {
            status: value,
        });
    }
}
